
import { computed, defineComponent, onMounted, ref } from 'vue';
import swal from 'sweetalert2';
import { generalStore } from '@/store';
import { useRoute } from 'vue-router';
import { Employee, EmployeeSchedule } from '@/models/Employee';
import { api } from '@/services/Api';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import OverlayModal from '../Calendar/OverlayModal.vue';
import CheckBoxInput from '@/components/CheckBoxInput.vue';
import { DayOfWeek } from '@/models/Enums';
import { Currency, IdName } from '@/models/Interfaces';
import Multiselect from '@vueform/multiselect';
import TimeInput from '@/components/TimeInput.vue';
import moment from 'moment';

export default defineComponent({
    props: {
        id: {
            type: Number,
            required: true
        }
    },
    components: { OverlayModal, CheckBoxInput, Multiselect, TimeInput },
    setup(props: any) {
        const { t } = useI18n();
        const route = useRoute();
        const employee = computed<Employee>(() => generalStore.state.employee);
        const openTagsManagementModal = ref(false);
        const openAddTagsModal = ref(false);
        const openAddProductsModal = ref(false);
        const currencies = computed<Currency[]>(() => generalStore.getters.currencies);
        const weekDayNames = DayOfWeek;
        const selectedTags = ref<string[]>(employee.value?.tags?.map(t => t.id) ?? []);
        const selectedProducts = ref<string[]>(employee.value?.products?.map(t => t.id) ?? []);
        const tags = ref<IdName[]>([]);
        const products = ref<IdName[]>([]);
        const filteredTags = ref<IdName[]>([]);
        const filteredProducts = ref<IdName[]>([]);
        const newTagName = ref('');
        const searchTagName = ref('');
        const searchProductName = ref('');
        const editingTag = ref<IdName | null>(null);
        const copyTimeToAllActiveDaysToggle = ref(false);
        const firstActiveDay = computed(() => employee.value.employeeSchedules.find(x => x.active)?.dayOfWeek ?? -1);

        const headerTitle = computed(() => {
            return employee.value?.id && employee.value?.id !== '0' ? 'employee.edit-employee' : 'employee.new-employee';
        });

        const removeLeadingZeroes = () => {
            if (employee.value.ratePerHour) employee.value.ratePerHour = Number(employee.value.ratePerHour);
        };

        async function getEmployee(id: string): Promise<Employee | null> {
            if (!id) return null;
            if (!employee.value || employee.value.id.toString() != id) {
                swal.showLoading();
                const response = await api.getEmployee(id);
                if (response.data) {
                    generalStore.commit('setEmployee', response.data);
                    const employeeSchedules = [];
                    for (const item in weekDayNames) {
                        if (!isNaN(Number(item))) {
                            const scheduleIndex = employee.value.employeeSchedules.findIndex(x => x.dayOfWeek == Number(item));
                            if (scheduleIndex >= 0) {
                                const existItem = employee.value.employeeSchedules[scheduleIndex];
                                existItem.active = true;
                                employeeSchedules.push(existItem);
                            } else {
                                const newItem = new EmployeeSchedule();
                                newItem.dayOfWeek = Number(item);
                                employeeSchedules.push(newItem);
                            }
                        }
                    }
                    employee.value.employeeSchedules = employeeSchedules;
                    selectedTags.value = employee.value.tags.map(t => t.id);
                    selectedProducts.value = employee.value.products.map(p => p.id);
                    swal.close();
                } else {
                    const result = await swal.fire({
                        icon: 'error',
                        title: 'Such employee does not exist.',
                        text: response.errorMessage,
                        confirmButtonText: t('alert.backToCatalog')
                    });
                    if (result.isConfirmed) {
                        router.push({ name: 'EmployeesTable' });
                    }
                }
            }

            return generalStore.state.employee;
        }

        if (route.params.id.toString() === '0') {
            for (const item in weekDayNames) {
                if (!isNaN(Number(item))) {
                    const newItem = new EmployeeSchedule();
                    newItem.dayOfWeek = Number(item);
                    employee.value.employeeSchedules.push(newItem);
                }
            }
        }

        async function onMountedAction() {
            swal.showLoading();
            const newId = route.params.id.toString();
            if (newId && newId !== '0') {
                await getEmployee(newId);
            }

            const response = await api.getTagsAndProducts();
            if (response.data) {
                generalStore.commit('updateEmployeesTags', response.data.tags);
                generalStore.commit('updateEmployeesProducts', response.data.products);
                tags.value = response.data.tags;
                filteredTags.value = response.data.tags;
                products.value = response.data.products;
                filteredProducts.value = response.data.products;
            }
            swal.close();
        }

        function addClassIfNotExist(id: string) {
            const element = document.getElementById(id);
            if (element && !element.classList.contains('invalid')) {
                element.classList.add('invalid');
            }
        }

        function removeClassIfNotExist(id: string) {
            const element = document.getElementById(id);
            if (element && element.classList.contains('invalid')) {
                element.classList.remove('invalid');
            }
        }

        function validateDayOfWeeks() {
            let isValid = true;
            for (let i = 0; i < employee.value.employeeSchedules.length; i++) {
                const schedule = employee.value.employeeSchedules[i];
                if (!schedule.active) {
                    continue;
                }
                if (schedule.startTime == '') {
                    addClassIfNotExist(`dayOfWeekStartTime-${schedule.dayOfWeek}`);
                    isValid = false;
                }
                if (schedule.endTime == '') {
                    addClassIfNotExist(`dayOfWeekEndTime-${schedule.dayOfWeek}`);
                    isValid = false;
                }

                const startTime = moment(schedule.startTime, 'hh:mm');
                const endTime = moment(schedule.endTime, 'hh:mm');
                if (startTime.isSameOrAfter(endTime)) {
                    addClassIfNotExist(`dayOfWeekStartTime-${schedule.dayOfWeek}`);
                    addClassIfNotExist(`dayOfWeekEndTime-${schedule.dayOfWeek}`);
                    isValid = false;
                }
            }
            return isValid;
        }

        function removeInvalidClass() {
            for (const item in weekDayNames) {
                if (!isNaN(Number(item))) {
                    removeClassIfNotExist(`dayOfWeekStartTime-${item}`);
                    removeClassIfNotExist(`dayOfWeekEndTime-${item}`);
                }
            }
        }

        const saveEmployee = async (event: any) => {
            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
                swal.fire({
                    icon: 'warning',
                    title: t('alert.mandatoryfields'),
                    confirmButtonText: t('button.close')
                });
                return;
            }

            removeInvalidClass();
            const isValid = validateDayOfWeeks();
            if (!isValid) {
                return;
            }
            swal.showLoading();
            const employeeToSave: Employee = JSON.parse(JSON.stringify(employee.value));
            employeeToSave.employeeSchedules = employeeToSave.employeeSchedules.filter(x => x.active);
            const response = await api.saveEmployee(employeeToSave);
            if (response.data) {
                const responseEmployee = response.data;
                const employeeSchedules = [];
                for (const item in weekDayNames) {
                    if (!isNaN(Number(item))) {
                        const scheduleIndex = response.data.employeeSchedules.findIndex(x => x.dayOfWeek == Number(item));
                        if (scheduleIndex >= 0) {
                            const existItem = response.data.employeeSchedules[scheduleIndex];
                            existItem.active = true;
                            employeeSchedules.push(existItem);
                        } else {
                            const newItem = new EmployeeSchedule();
                            newItem.dayOfWeek = Number(item);
                            employeeSchedules.push(newItem);
                        }
                    }
                }
                responseEmployee.employeeSchedules = employeeSchedules;
                generalStore.commit('setEmployee', responseEmployee);
                await router.push({ name: 'EmployeeView', params: { id: employee.value.id } });
                swal.close();
            } else {
                swal.fire({
                    icon: 'error',
                    title: 'Oops',
                    text: response.errorMessage
                });
            }
        };

        const deleteEmployee = async () => {
            const swalResult = await swal.fire({
                title: t('alert.areYouSure'),
                text: t('employee.are-you-want-delete-employee'),
                icon: 'warning',
                customClass: {
                    confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-4',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: t('alert.yesContinue'),
                cancelButtonText: t('button.cancel')
            });

            if (swalResult.isConfirmed) {
                swal.showLoading();
                const resultCheckAssign = await api.checkPastFutureAssignEmployee(employee.value.id);
                if (resultCheckAssign.data) {
                    if (resultCheckAssign.data.isAssignedInPast) {
                        swal.fire({
                            text:
                                'This employee connected to existing orders. Therefor it can not be deleted but will be set to Not Active',
                            icon: 'warning'
                        });
                        return;
                    }
                    if (resultCheckAssign.data.isAssignInFuture) {
                        const swalResult2 = await swal.fire({
                            title: t('alert.areYouSure'),
                            text: 'This employee is already connected to future orders. Do you want to unassign this employee?',
                            icon: 'warning',
                            customClass: {
                                confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-4',
                                cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                            },
                            buttonsStyling: false,
                            showCancelButton: true,
                            confirmButtonText: t('alert.yesContinue'),
                            cancelButtonText: t('button.cancel')
                        });

                        if (!swalResult2.isConfirmed) {
                            return;
                        }
                    }
                    const response = await api.deleteEmployee(employee.value.id);
                    if (response.data) {
                        swal.fire({
                            icon: 'success',
                            title: 'Deleted'
                        });
                        router.push({ name: 'EmployeesTable' });
                    } else {
                        swal.fire({
                            icon: 'error',
                            title: 'Oops',
                            text: response.errorMessage
                        });
                    }
                }
            }
        };

        const copyTimeToAllActiveDays = (firstActiveDay: number) => {
            const firstActiveDaySchedule = employee.value.employeeSchedules.find(x => x.dayOfWeek === firstActiveDay);

            if (firstActiveDaySchedule && copyTimeToAllActiveDaysToggle.value) {
                for (const employeeSchedule of employee.value.employeeSchedules) {
                    employeeSchedule.startTime = firstActiveDaySchedule.startTime;
                    employeeSchedule.endTime = firstActiveDaySchedule.endTime;
                }
            }
        };

        const setProductToEmployee = (product: IdName) => {
            if (employee.value.products.map(t => t.id).includes(product.id)) {
                employee.value.products = employee.value.products.filter(t => t.id !== product.id);
            } else {
                employee.value.products.push(product);
            }
        };

        const removeProductFromEmployee = (product: IdName) => {
            if (employee.value.products.map(t => t.id).includes(product.id)) {
                employee.value.products = employee.value.products.filter(t => t.id !== product.id);
                selectedProducts.value = selectedProducts.value.filter(id => id !== product.id);
            }
        };

        const searchProducts = () => {
            filteredProducts.value = JSON.parse(JSON.stringify(products.value));

            if (searchProductName.value) {
                filteredProducts.value = filteredProducts.value.filter(t => t.name.includes(searchProductName.value));
            }
        };

        const saveTag = async (isNewTag: boolean) => {
            swal.showLoading();
            const tag: IdName = {
                id: isNewTag ? '' : editingTag.value!.id,
                name: isNewTag ? newTagName.value : editingTag.value!.name
            };

            const response = await api.createSaveEmployeeTag(tag);
            if (response.data) {
                if (isNewTag) {
                    tags.value.push(response.data);
                    newTagName.value = '';
                } else {
                    tags.value.find(t => t.id === editingTag.value!.id)!.name = editingTag.value!.name;
                    editingTag.value = null;
                }
                swal.close();
            } else {
                swal.fire({
                    icon: 'error',
                    title: 'Oops',
                    text: response.errorMessage
                });
            }
        };

        const searchTags = () => {
            filteredTags.value = JSON.parse(JSON.stringify(tags.value));

            if (searchTagName.value) {
                filteredTags.value = filteredTags.value.filter(t => t.name.includes(searchTagName.value));
            }
        };

        const editTag = (tag: IdName) => {
            editingTag.value = JSON.parse(JSON.stringify(tag));
        };

        const deleteTag = async (id: string) => {
            swal.showLoading();
            const existTag = tags.value.find(t => t.id == id);
            if (existTag) {
                const result = await swal.fire({
                    icon: 'warning',
                    title: t('employee.are-you-want-delete-tag'),
                    customClass: {
                        confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                        denyButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                    },
                    buttonsStyling: false,
                    showDenyButton: true,
                    showCloseButton: true,
                    confirmButtonText: t('alert.yesDeleteIt'),
                    denyButtonText: t('button.cancel')
                });
                if (result.isConfirmed) {
                    const response = await api.deleteEmployeeTag(id);
                    if (response.data) {
                        tags.value.splice(tags.value.indexOf(existTag), 1);
                        swal.close();
                    } else {
                        swal.fire({
                            icon: 'error',
                            title: 'Oops',
                            text: response.errorMessage
                        });
                    }
                }
            }
        };

        const setTagToEmployee = (tag: IdName) => {
            if (employee.value.tags.map(t => t.id).includes(tag.id)) {
                employee.value.tags = employee.value.tags.filter(t => t.id !== tag.id);
            } else {
                employee.value.tags.push(tag);
            }
        };

        const removeTagFromEmployee = (tag: IdName) => {
            if (employee.value.tags.map(t => t.id).includes(tag.id)) {
                employee.value.tags = employee.value.tags.filter(t => t.id !== tag.id);
                selectedTags.value = selectedTags.value.filter(id => id !== tag.id);
            }
        };

        function closeTagsManagementModal() {
            editingTag.value = null;
            openTagsManagementModal.value = false;
        }

        function closeAddTagsModal() {
            searchTagName.value = '';
            openAddTagsModal.value = false;
        }

        function closeAddProductsModal() {
            searchProductName.value = '';
            openAddProductsModal.value = false;
        }

        function cancel() {
            router.go(-1);
        }

        const submitForm = async (event: any) => {
            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
                return;
            } else {
                await saveTag(true);
            }
        };

        onMounted(onMountedAction);
        return {
            saveEmployee,
            deleteEmployee,
            saveTag,
            editingTag,
            editTag,
            closeTagsManagementModal,
            closeAddTagsModal,
            closeAddProductsModal,
            cancel,
            employee,
            openTagsManagementModal,
            openAddTagsModal,
            openAddProductsModal,
            searchTags,
            searchProducts,
            newTagName,
            searchTagName,
            searchProductName,
            weekDayNames,
            tags,
            filteredTags,
            filteredProducts,
            deleteTag,
            selectedTags,
            selectedProducts,
            setTagToEmployee,
            setProductToEmployee,
            removeTagFromEmployee,
            removeProductFromEmployee,
            currencies,
            headerTitle,
            firstActiveDay,
            copyTimeToAllActiveDays,
            copyTimeToAllActiveDaysToggle,
            removeLeadingZeroes,
            submitForm
        };
    }
});
